import {EnhancedDetailsList} from '../EnhancedDetailsList';
import {ColumnKey} from '../EnhancedDetailsList/IEnhancedDetailsList';
import {Document} from '../../../models';
import {DocumentSystem} from '../../../models/DocumentSystem';
import {DocumentVersion} from '../../../models/DocumentVersion';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {sortByTitle} from '../../../helpers/arrayMehods';
import {useService} from '../../../hooks';
import {UserDto} from '../../../services/generated';
import {getColumnsAdd, getColumnsEdit, getColumnsView} from './documentAndFileColumns';
import {DocumentListUpdateModal} from './DocumentListUpdateModal';

function mapChangedDocumentToDocument(changedItem: DocumentSystem): DocumentSystem {
  return changedItem;
}

function mapNewItemToChangedItem(document: Document) {
  return new DocumentVersion({
    order: undefined as any as number,
    active: true,
    auditable: false,
    approved: document.approved,
    id: document.id,
    link: document.link,
    deleted: document.deleted,
    version: document.version,
    approvedBy: document.approvedBy,
    documentId: document.documentId,
    documentKind: document.documentKind,
    editStatus: document.editStatus,
    function: document.function,
    inReview: document.inReview,
    isLatestVersion: document.isLatestVersion,
    languageCode: document.languageCode,
    lifecycleStatus: document.lifecycleStatus,
    name: document.name,
    ownerProcessId: document.ownerProcessId,
    prepared: document.prepared,
    relevantToId: document.relevantToId,
    relevantTo: document.relevantTo,
    reviewedBy: document.reviewedBy,
    revisionId: document.revisionId,
    securityLevel: document.securityLevel,
    specialities: document.specialities,
    title: document.title,
    titleEn: document.titleEn,
    url: document.url,
    operatingUnit: document.operatingUnit,
    projectSizeCategories: document.projectSizeCategories,
    relativeUrl: document.relativeUrl,
    industries: document.industries,
    contentType: document.contentType,
    documentOwner: document.documentOwner,
    created: '',
    createdBy: undefined as any as UserDto,
    modified: null,
    modifiedBy: undefined as any as UserDto,
    businessLines: document.businessLines
  });
}

function mapDocumentToDocumentSystem(document: Document) {
  return new DocumentSystem({
    order: undefined as any as number,
    active: true,
    auditable: false,
    approved: document.approved,
    id: document.id,
    link: document.link,
    deleted: document.deleted,
    version: document.version,
    approvedBy: document.approvedBy,
    documentId: document.documentId,
    documentKind: document.documentKind,
    editStatus: document.editStatus,
    function: document.function,
    inReview: document.inReview,
    isLatestVersion: document.isLatestVersion,
    languageCode: document.languageCode,
    lifecycleStatus: document.lifecycleStatus,
    name: document.name,
    ownerProcessId: document.ownerProcessId,
    prepared: document.prepared,
    relevantToId: document.relevantToId,
    relevantTo: document.relevantTo,
    reviewedBy: document.reviewedBy,
    revisionId: document.revisionId,
    securityLevel: document.securityLevel,
    specialities: document.specialities,
    title: document.title,
    titleEn: document.titleEn,
    url: document.url,
    operatingUnit: document.operatingUnit,
    projectSizeCategories: document.projectSizeCategories,
    industries: document.industries,
    contentType: document.contentType,
    documentOwner: document.documentOwner,
    businessLines: document.businessLines
  });
}

export const DocumentAndFile: FC<{
  data: DocumentSystem[] | null;
  changedDocuments?: DocumentSystem[];
  hiddenColumns?: ColumnKey[];
  allowAdd?: boolean;
  ownerProcessId?: number;
  pageSize?: number;
  pdcOperatingUnit?: string | null;
}> = ({data, changedDocuments, allowAdd, ownerProcessId, pageSize, pdcOperatingUnit}) => {
  const {getDocuments} = useService();
  useEffect(() => {
    if (!allowAdd) return;
    (async () => {
      const documentsResponse = await getDocuments();
      if (!documentsResponse.result) return;
      setDocuments(documentsResponse.result.sort(sortByTitle));
    })();
  }, [getDocuments, allowAdd]);

  const [isAddModal, setIsAddModal] = useState(false);
  const [editItem, setEditItem] = useState<DocumentSystem | null>(null);
  const [documents, setDocuments] = useState<Document[] | undefined>();
  const [documentsChangedCount, setDocumentsChangedCount] = useState(0);
  const [parents, setParents] = useState<DocumentSystem[] | null>(null);
  const changedItems = useMemo(() => changedDocuments || [], [changedDocuments]);

  const refreshDocuments = useMemo(
    () => async () => {
      const docsResponse = await getDocuments();
      if (!docsResponse.result) return;
      setDocuments(docsResponse.result.sort(sortByTitle));
    },
    [getDocuments, setDocuments]
  );

  const onOpen = useCallback(
    (item: DocumentSystem) => {
      let possibleParents: DocumentSystem[] = [];
      data?.forEach(x => possibleParents.push(x));

      changedDocuments?.forEach(x => {
        const possibleParent = possibleParents.find(y => y.id === x.id);
        if (possibleParent === undefined) {
          possibleParents.push(x);
        }
      });

      if (item.relevantToId !== null) {
        let currentParent = possibleParents.find(x => x.id === item.relevantToId);

        if (currentParent === undefined) {
          const document = documents?.find(x => x.id === item.relevantToId);

          if (document !== undefined) {
            currentParent = mapDocumentToDocumentSystem(document);
            possibleParents.push(currentParent);
          }
        }
      }

      setParents(possibleParents);
      setIsAddModal(true);
    },
    [changedDocuments, data, documents]
  );

  const onClose = useCallback(() => {
    setEditItem(null);
    setIsAddModal(false);
  }, []);

  const onEditClick = useCallback(
    (item: DocumentSystem) => {
      setEditItem(item);
      onOpen(item);
    },
    [onOpen]
  );

  const columnsView = useMemo(
    () => getColumnsView(changedDocuments, ownerProcessId),
    [changedDocuments, ownerProcessId]
  );
  const columnsEdit = useMemo(
    () => getColumnsEdit(changedDocuments, ownerProcessId, onEditClick),
    [changedDocuments, ownerProcessId, onEditClick]
  );
  const columnsAdd = useMemo(
    () => getColumnsAdd(changedDocuments, ownerProcessId, pdcOperatingUnit),
    [changedDocuments, ownerProcessId, pdcOperatingUnit]
  );

  const updateDocuments = useCallback(
    (modifiedDoc: DocumentVersion | null, existingDocs: DocumentSystem[] | undefined | null) => {
      const modifiedDocs = existingDocs?.filter(item => item.id === modifiedDoc?.id);
      modifiedDocs?.forEach(doc => {
        doc.relevantTo = modifiedDoc ? modifiedDoc.relevantTo : null;
        doc.relevantToId = modifiedDoc ? modifiedDoc.relevantToId : null;
      });
      return !!modifiedDocs?.length;
    },
    []
  );
  const onUpdated = useCallback(
    (modifiedDoc: Document) => {
      const result = modifiedDoc ? mapNewItemToChangedItem(modifiedDoc) : null;
      if (changedDocuments) {
        updateDocuments(result, changedDocuments);
        setDocumentsChangedCount(documentsChangedCount + 1);
      }
      if (data) {
        updateDocuments(result, data);
      }

      refreshDocuments();
      setEditItem(null);
      setIsAddModal(false);
    },
    [changedDocuments, data, updateDocuments, refreshDocuments, documentsChangedCount]
  );

  return (
    <>
      <EnhancedDetailsList<DocumentSystem, DocumentSystem, Document>
        columns={allowAdd ? columnsEdit : columnsView}
        items={data || []}
        allowAdd={allowAdd}
        addLabel="Add documents from PDC"
        columnsForAdd={columnsAdd}
        itemsToAdd={documents}
        changedItems={changedItems}
        mapChangedItemToItem={mapChangedDocumentToDocument}
        mapNewItemToChangedItem={mapNewItemToChangedItem}
        ownerProcessId={ownerProcessId}
        pageSize={pageSize}
        frozen={allowAdd ? false : true}
      />
      <DocumentListUpdateModal
        isVisible={isAddModal}
        onClose={onClose}
        editItem={editItem}
        onUpdated={onUpdated}
        parents={parents}
      />
    </>
  );
};
