/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {DocumentDto} from '../models/DocumentDto';
import type {DocumentVersionDto} from '../models/DocumentVersionDto';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import {DocumentUsedDto} from '../models/DocumentUsedDto';
import {DocumentRelevantToDto} from '../models/DocumentRelevantToDto';
import {BatchAcknowledgementSaveDto} from '../models/BatchAcknowledgementSaveDto';
import {BatchAcknowledgementElementDto} from '../models/BatchAcknowledgementElementDto';
import {BatchAcknowledgementDto} from '../models/BatchAcknowledgementDto';
import {DocumentUpdateDto} from '../models/DocumentUpdateDto';
import { DocumentIdAngLanguageCodeDto } from '../models/DocumentIdAngLanguageCodeDto';

export class DocumentService {
  /**
   * @returns DocumentDto Success
   * @throws ApiError
   */
  public static getDocument(): CancelablePromise<Array<DocumentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/document'
    });
  }

  /**
   * @param id
   * @returns DocumentUsedDto Success
   * @throws ApiError
   */

  public static getRelatedDocuments(id: string, languageCode: string): CancelablePromise<Array<DocumentUsedDto>> {
    var body = {
      documentId: id,
      languageCode: languageCode
    };
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Document/Children',
      body: body,
    });
  }

  public static createDynamicLink(requestBody?: DocumentIdAngLanguageCodeDto): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Document/dynamicLink',
      body: requestBody
    });
  }

  public static changeRelevantTo(requestBody?: DocumentRelevantToDto): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Document/UpdateRelevantTo',
      body: requestBody,
      mediaType: 'application/json'
    });
  }
  /**
   * @param id
   * @returns DocumentVersionDto Success
   * @throws ApiError
   */
  public static getApiDocumentVersion(id: number): CancelablePromise<Array<DocumentVersionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Document/{id}/Version',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns BatchAcknowledgementDto Success
   * @throws ApiError
   */
  public static getApiBatchAcknowledgement(
    batchId: number
  ): CancelablePromise<BatchAcknowledgementDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Document/acknowledgement/{batchId}',
      path: {
        batchId: batchId
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns BatchAcknowledgementSaveDto
   * @throws ApiError
   */
  public static postApiBatchAcknowledgement(
    requestBody: BatchAcknowledgementSaveDto
  ): CancelablePromise<Array<BatchAcknowledgementSaveDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Document/acknowledgement',
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns BatchAcknowledgementElementDto Success
   * @throws ApiError
   */
  public static putAcknowledgementOpened(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Document/acknowledgement/opened/{id}',
      path: {
        id: id
      },
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns BatchAcknowledgementElementDto Success
   * @throws ApiError
   */
  public static putAcknowledgementConfirmed(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Document/acknowledgement/confirmed/{id}',
      path: {
        id: id
      },
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns BatchAcknowledgementElementDto Success
   * @throws ApiError
   */
  public static putAcknowledgementFinished(batchId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Document/acknowledgement/finish/{batchId}',
      path: {
        batchId: batchId
      },
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns BatchAcknowledgementElementDto Success
   * @throws ApiError
   */
  public static putDocumentUpdate(
    id: number,
    requestBody: DocumentUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Document/update/{id}',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }
}
