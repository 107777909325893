import {FC, useContext, useMemo} from 'react';
import {sortByTitle} from '../../../../helpers/arrayMehods';
import {DocumentSlim} from '../../../../models/DocumentSlim';
import {Accordion} from '../../../common/Accordion';
import {ColumnKey} from '../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {DocumentAndFile} from '../../../common/ProcessSections';
import {getControlled} from '../helpers';
import styles from '../Process.module.scss';
import {DictionariesContext} from '../../../../providers';

interface IProcessDocumentsProps {
  documents: DocumentSlim[] | null;
}

export const ProcessDocuments: FC<IProcessDocumentsProps> = ({documents}) => {
  const {globalFiltersFunction} = useContext(DictionariesContext);

  const hiddenColumns = useMemo(
    () => [
      ColumnKey.Active,
      ColumnKey.Order,
      ColumnKey.DocumentOwner,
      ColumnKey.FunctionString,
      ColumnKey.Specialty,
      ColumnKey.ProjectSizeCategory,
      ColumnKey.ApprovedDate,
      ColumnKey.SecurityLevel,
      ColumnKey.Language
    ],
    []
  );

  const filteredDocuments =
    getControlled(documents)?.filter(globalFiltersFunction).sort(sortByTitle) || null;

  return (
    <div className={styles.documentsWrapper}>
      <Accordion isOpen={!!filteredDocuments?.length} header={'Documents'}>
        <DocumentAndFile data={filteredDocuments} hiddenColumns={hiddenColumns} />
      </Accordion>
    </div>
  );
};
