import {MessageBar, MessageBarType} from '@fluentui/react';
import {useRef, useEffect, useState} from 'react';

export const EnhancedDetailsListFooter = () => {
  const elementRef: React.Ref<HTMLDivElement> = useRef(null);
  const [width, setWitdh] = useState<number>();

  useEffect(() => {
    const width =
      elementRef.current?.parentElement?.querySelector('.ms-DetailsHeader')?.clientWidth;
    if (width) {
      setWitdh(width);
    }
  }, []);

  return (
    <MessageBar
      styles={{
        root: {
          width: `${width}px`
        }
      }}
      ref={elementRef}
      messageBarType={MessageBarType.error}
      isMultiline={false}>
      No results.
    </MessageBar>
  );
};
