import bgPattern from './bgPattern.jpg';
import bgPatternMedium from './bgPatternMedium.png';
import bgPatternSmall from './bgPatternSmall.jpg';
import homeIcon from './Home.svg';
import homeIconFull from './HomeFull.svg';
import people from './people.png';

import think from './ABBicon_think.png';

export const Images: {[key: string]: string} = {
  people,
  bgPattern,
  bgPatternSmall,
  bgPatternMedium,
  homeIcon,
  homeIconFull,
  think
};
