/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Process
 *
 * 1 = Document
 *
 * 2 = ExternalDocumentLink
 *
 * 3 = ExternalLink
 *
 * 4 = ActivityInput
 *
 * 5 = ActivityOutput
 *
 * 6 = PDC
 */
export enum SearchResultType {
  Process = 0,
  Document = 1,
  ExternalDocumentLink = 2,
  ExternalLink = 3,
  ActivityInput = 4,
  ActivityOutput = 5,
  PDC = 6
}
