import {FC, useCallback, useState} from 'react';
import {ProcessComment, ProcessCommentManage} from '../../../../../models';
import {useComments} from '../../../../../hooks/services/useComments';
import {ModalWithHeader} from '../../../../common/ModalWithHeader';
import {FormFooter} from '../../../../common/FormFooter';
import {Richtext} from '../../../../common/Richtext';
import styles from './Feedback.module.scss';

interface IFeedbackReplyToProps {
  isOpen: boolean;
  comment: ProcessComment | ProcessCommentManage;
  processId: number;
  onClose: () => void;
  refresh: (skipLoading?: boolean) => void;
}

export const FeedbackReplyTo: FC<IFeedbackReplyToProps> = ({
  isOpen,
  comment,
  processId,
  refresh,
  onClose
}) => {
  const {replyToComment} = useComments();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const [counter, setCounter] = useState(1);
  const onAdd = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await replyToComment(processId, comment.id, content);
      setContent('');
      setCounter(counter + 1);
      await refresh();
    })();
  }, [comment, replyToComment, counter, refresh, content, processId]);
  return (
    <ModalWithHeader dismiss={onClose} isVisible={isOpen} header="Reply To:">
      <div
        className={`sun-editor sun-editor-editable no-border`}
        dangerouslySetInnerHTML={{
          __html: `<div class=${styles.replyTo}> ${comment.comment} </div>` || ''
        }}
      />
      <div className={styles.richTextContainer}>
        <Richtext key={counter} onChange={setContent} disable={isLoading} />
      </div>

      <FormFooter onCancel={onClose} onSubmit={onAdd} saveLabel="Reply To" isDisabled={isLoading} />
    </ModalWithHeader>
  );
};
