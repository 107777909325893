import {
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  UserDto,
  ViewProcessDto,
  BusinessLineDto
} from '../services/generated';
import {Activity} from './Activity';
import {DocumentSlim} from './DocumentSlim';
import {ProcessBaseView} from './ProcessBaseView';
import {Unit} from './Unit';

export class Process extends ProcessBaseView implements ViewProcessDto {
  public documents: Array<DocumentSlim> | null;
  public masterProcessId: number;
  public status: string | null;
  public unit: Unit | null;
  public version: number;

  public url: string = '';

  public created: string | null;
  public createdBy: UserDto | null;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public published: string | null;
  public publishedBy: UserDto | null;
  public function: FunctionDto | null;
  public industries: IndustryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public activityInputs: Activity[] | null;
  public activityOutputs: Activity[] | null;
  public businessLines: BusinessLineDto[] | null;

  constructor(process: ViewProcessDto) {
    super(process);
    this.documents = process.documents?.map(document => new DocumentSlim(document)) || null;
    this.masterProcessId = process.masterProcessId;
    this.status = process.status;
    this.unit = process.unit ? new Unit(process.unit) : null;
    this.version = process.version;
    this.created = process.created;
    this.createdBy = process.createdBy;
    this.modified = process.modified;
    this.modifiedBy = process.modifiedBy;
    this.published = process.published;
    this.publishedBy = process.publishedBy;
    this.function = process.function;
    this.industries = process.industries;
    this.specialities = process.specialities;
    this.projectSizeCategories = process.projectSizeCategories;

    this.activityInputs = process.activityInputs?.map(item => new Activity(item)) || null;
    this.activityOutputs = process.activityOutputs?.map(item => new Activity(item)) || null;

    this.businessLines = process.businessLines;

    Object.defineProperty(this, 'url', {
      get() {
        return `/unit/${this.unit.id}/process/${this.id}`;
      }
    });
  }
}
