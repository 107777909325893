import {
  Dropdown,
  IDropdownOption,
  IDropdownStyleProps,
  IDropdownStyles,
  IStyleFunctionOrObject,
  Spinner
} from '@fluentui/react';
import {FC, useCallback, useMemo, useState} from 'react';
import {InputValidationError} from '../Input/ValidationError';
import {IDictionaryDropdownProps, IDictionaryItem} from './IDictionaryDropdownProps';

export const dropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles> = {
  title: {
    borderColor: 'var(--abb-grey-4)',
    fontSize: '13px'
  },
  dropdownOptionText: {
    fontSize: '13px'
  }
};

export const DictionaryDropdown: FC<IDictionaryDropdownProps> = ({
  defaultValue,
  onChange,
  className,
  disabled,
  options,
  required,
  disableErrorMessage,
  placeholder
}) => {
  const [hasValue, setHasValue] = useState(!!defaultValue);
  const dictionaryOptions = useMemo<IDropdownOption<IDictionaryItem>[]>(
    () =>
      options?.map(option => ({
        key: option.id,
        text: option.value || '',
        data: option
      })) || [],
    [options]
  );
  const onInputChange = useCallback(
    (
      event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
      index?: number | undefined
    ) => {
      onChange(option?.data);
      setHasValue(!!option?.data);
    },
    [onChange]
  );

  return !options ? (
    <Spinner />
  ) : (
    <>
      <Dropdown
        className={className}
        defaultSelectedKey={typeof defaultValue === 'number' ? defaultValue : defaultValue?.id}
        onChange={onInputChange}
        placeholder={placeholder || 'Select an option'}
        options={dictionaryOptions}
        disabled={disabled || options.length === 0}
        required={required}
        styles={dropdownStyles}
      />
      {!disableErrorMessage && !disabled && !hasValue && required ? (
        <InputValidationError message={'This value could not be empty. Select value.'} />
      ) : null}
    </>
  );
};
