import {Label} from '@fluentui/react';
import {useContext, useMemo} from 'react';

import {sortByTitle} from '../../../../helpers/arrayMehods';
import {HeadingWithDecoration} from '../../../common/HeadingWithDecoration';
import {LastEditor} from '../../../common/LastEditor';
import {Subscription} from '../../../common/Subscription';
import {ProcessDescriptions} from '../components/ProcessDescriptions';
import {ProcessDiagram} from '../components/ProcessDiagram';
import {ProcessDocuments} from '../components/ProcessDocuments';
import {ProcessExternalLinks} from '../components/ProcessExternalLinks';
import {ProcessOwner} from '../components/ProcessOwner';
import {ProcessWrapper} from '../components/Wrapper';
import styles from '../Process.module.scss';
import {useProcess} from './useProcess';
import {ProcessActivities} from '../components/ProcessActivities';
import {DictionariesContext} from '../../../../providers';
import {ProcessVersionHistory} from '../components/VersionHistory';
import {SendEmail} from '../components/SendEmail';
import {DictionaryPill} from '../../../common/DictionaryPill';
import {Feedback} from '../components/Feedback/Feedback';
import {Helmet} from 'react-helmet';

export const Process = () => {
  const {globalFiltersFunction} = useContext(DictionariesContext);
  const {isLoading, process, canEdit, canEditDiagram, helmetTitle, publishedProcessesForUnit} =
    useProcess();
  const nextUpdate = process?.nextUpdate ? process?.nextUpdate.split('T')[0] : undefined;
  const externalLinks = useMemo(() => process?.externalLinks?.sort(sortByTitle) || null, [process]);
  const activityInputs = useMemo(
    () => process?.activityInputs?.filter(globalFiltersFunction).sort(sortByTitle) || null,
    [process, globalFiltersFunction]
  );
  const activityOutputs = useMemo(
    () => process?.activityOutputs?.filter(globalFiltersFunction).sort(sortByTitle) || null,
    [process, globalFiltersFunction]
  );

  return (
    <ProcessWrapper
      apiMsg={''}
      isLoading={isLoading}
      process={process}
      canEdit={canEdit}
      publishedProcessesForUnit={publishedProcessesForUnit}>
      {process ? (
        <>
          {helmetTitle && (
            <Helmet>
              <title>{helmetTitle}</title>
            </Helmet>
          )}
          <HeadingWithDecoration text={process.title || ''} />
          <div className={styles.processMetaWrapper}>
            <div className={styles.processMeta}>
              {process.processOwner ? <ProcessOwner processOwner={process.processOwner} /> : null}
              <SendEmail
                unitId={process.unit?.id || 0}
                processId={process.id}
                processTitle={process.title || ''}
                processOwner={process.processOwner || undefined}
              />
              <Subscription processId={process.id} />
              <div className={styles.update}>
                {nextUpdate ? (
                  <>
                    <Label>Next process update:</Label> {nextUpdate}
                  </>
                ) : null}
              </div>
            </div>
            <LastEditor
              personData={process?.modifiedBy?.azureId}
              whenEdited={process.modified || ''}
              whenPublished={process.published || ''}
            />
            <DictionaryPill label="Function" value={process.function} />
            <DictionaryPill label="Project Size Category" value={process.projectSizeCategories} />
            <DictionaryPill label="Industry" value={process.industries} />
            <DictionaryPill label="Speciality" value={process.specialities} />
          </div>
          <ProcessDiagram
            processId={process.id}
            processTitle={process.title || ''}
            unitId={process.unit?.id || 0}
            canEdit={canEditDiagram}
          />
          <ProcessDescriptions process={process} processUnitTitle={process.unit?.title || ''} />
          <ProcessDocuments documents={process.documents} />
          <ProcessExternalLinks externalLinks={externalLinks} />
          <ProcessActivities activities={activityInputs} isInput unitId={process.unit?.id || 0} />
          <ProcessActivities
            activities={activityOutputs}
            isInput={false}
            unitId={process.unit?.id || 0}
          />
          <ProcessVersionHistory processId={process.id} />
          <Feedback process={process} unitId={process.unit?.id || 0} />
        </>
      ) : null}
    </ProcessWrapper>
  );
};
