import {useMemo} from 'react';
import {Document, DocumentInUnit} from '../../models';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {DocumentService, DocumentUpdateDto} from '../../services/generated';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';
import {DocumentRelevantToDto} from '../../services/generated/models/DocumentRelevantToDto';
import { DocumentIdAngLanguageCodeDto } from '../../services/generated/models/DocumentIdAngLanguageCodeDto';

export const useDocument = () => {
  const requestWrapper = useApiService();

  /** Returns a list of all units */
  const getDocuments = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<Document[]> => {
    return await requestWrapper(
      DocumentService.getDocument(),
      result => result.map(item => new Document(item)),
      log
    );
  };

  const getRelatedDocuments = async (
    documentId: string,
    languageCode: string,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<DocumentInUnit[]> => {
    return await requestWrapper(
      DocumentService.getRelatedDocuments(documentId, languageCode),
      result => result.map(item => new DocumentInUnit(item)),
      log
    );
  };

  const getDynamicLink = async (
    doc: DocumentIdAngLanguageCodeDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'Dynamic Link created and copied to clipboard'
    }
  ): ApiResponsePending<string> => {
    return await requestWrapper(DocumentService.createDynamicLink(doc), result => result, log);
  };

  const changeRelevantTo = async (
    doc: DocumentRelevantToDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Document Related To updated successfully`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      DocumentService.changeRelevantTo(doc),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const updateDocument = async (
    id: number,
    data: DocumentUpdateDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Document details updated (document id or language code)`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      DocumentService.putDocumentUpdate(id, data),
      (result, apiCode) => !apiCode,
      log
    );
  };

  return useMemo(
    () => ({
      getDocuments,
      getRelatedDocuments,
      getDynamicLink,
      changeRelevantTo,
      updateDocument
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
