import {
  ComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  SelectableOptionMenuItemType,
  Spinner
} from '@fluentui/react';
import {Document} from '../../../models';
import {FC, useCallback, useEffect, useState} from 'react';
import {TheButton} from '../TheButton';
import styles from './LinksListCreate.module.scss';
import {useDocument} from '../../../hooks/services/useDocument';
import {DocumentSystem} from '../../../models/DocumentSystem';
interface DocumentListToUpdateProps {
  editItem: DocumentSystem | null;
  onUpdated: (modifiedDoc: Document) => void;
  parents: DocumentSystem[] | null
}
const comboBoxStyles: Partial<IComboBoxStyles> = { callout: { width: 1000 } };
export const DocumentListToUpdate: FC<DocumentListToUpdateProps> = ({editItem, onUpdated, parents}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [relevantToId, setRelevantToId] = useState<number | null>(
    editItem ? editItem.relevantToId : null
  );
  const {changeRelevantTo, getDocuments} = useDocument();
  const [documents, setDocuments] = useState<Document[]>();
  const documentId = editItem?.id;

  const refresh = useCallback(async () => {
    setIsLoading(true);
    const response = await getDocuments();
    if (response.result) {
      setDocuments(response.result);
    }
    setIsLoading(false);
  }, [getDocuments]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  
  const prepareTitle = (item: DocumentSystem): string => {
    return item ? `${item.title} | ${item.documentId} | ${item.languageCode}` : '';
  };
  const prepareCorrectRelevantToIdValue = (option: IComboBoxOption | undefined): number | null => {
    if(option === undefined)
    {
      return null;
    }
    const result = option.key as number > 0 ? option.key as number : null
    return result
  }
  const onSubmit = useCallback(async () => {
    if (!documentId) return;
    setIsLoading(true);
    const changeParent = {
      documentId,
      relevantToId
    };
    if (editItem) {
      await changeRelevantTo(changeParent);
      const modifiedDoc = documents?.find(x => x.id === documentId);
      const newParent = documents?.find(x => x.id === relevantToId);
      if (modifiedDoc) {
        const result = new Document({
          ...modifiedDoc,
          relevantTo: newParent ? newParent.documentId : null,
          relevantToId: newParent ? newParent.id : null
        });
        onUpdated(result);
      }
      setIsLoading(false);
    }
  }, [documentId, editItem, onUpdated, documents, changeRelevantTo, relevantToId]);
  const comboBoxOptions = (editeItemId: number | undefined): IComboBoxOption[] => {
    const filteredDocuments = parents
      ?.filter(x => x.id !== editeItemId)
      .map(
        x =>
          ({
            key: x.id,
            text: prepareTitle(x)
          } as IComboBoxOption)
      );
    return [
      {
        key: 'Header',
        text: 'Title | Document ID | Language Code',
        itemType: SelectableOptionMenuItemType.Header
      },
      {
        key: 'Divider',
        text: '-',
        itemType: SelectableOptionMenuItemType.Divider
      },
      {
        key: 0,
        text: ''
      },
      ...(filteredDocuments ? filteredDocuments.sort((a, b) => (a.text > b.text ? 1 : -1)) : [])
    ];
  };
  return (
    <>
      <div>
        <ComboBox
          selectedKey={relevantToId || null}
          label="Parent"
          options={comboBoxOptions(editItem?.id)}
          styles={comboBoxStyles}
          onChange={(e, value) => setRelevantToId(prepareCorrectRelevantToIdValue(value))}
          allowFreeInput
          autoComplete="on"
          disabled={isLoading}
          required
        />
        <div className={styles.buttons}>
          <TheButton primary disabled={isLoading} onClick={() => onSubmit()}>
            Update
          </TheButton>
        </div>
        {isLoading ? <Spinner /> : null}
      </div>
    </>
  );
};

