import {useMemo} from 'react';
import styles from './RecentlyAddedDocument.module.scss';

import {EnhancedDetailsList, ProcessesColumn, SelectColumn} from '../EnhancedDetailsList';
import {ColumnKey, IEnhancedColumn} from '../EnhancedDetailsList/IEnhancedDetailsList';
import {DocumentRecent} from '../../../models';
import {MultiselectColumn} from '../EnhancedDetailsList/ColumnTypes/MultiselectColumn';
import {OpenPDCColumn} from '../EnhancedDetailsList/ColumnTypes/OpenPDCColumn';
import {Icon, TooltipHost} from '@fluentui/react';

export const RecentlyAddedDocument = (props: {data: DocumentRecent[]}) => {
  const {data} = props;

  const columns = useMemo(() => {
    const cols: IEnhancedColumn<DocumentRecent>[] = [
      {
        key: ColumnKey.Url,
        name: '',
        data: OpenPDCColumn
      },
      {
        key: ColumnKey.Title,
        exportToExcel: true,
        name: 'Title',
        data: SelectColumn,
        minWidth: 290
      },
      {
        key: ColumnKey.DocumentId,
        exportToExcel: true,
        name: 'Document Id',
        data: SelectColumn,
        minWidth: 135,
        onRender(item) {
          return (
            <>
              {item.documentId}
              {item.inReview && (
                <TooltipHost content="New revision is pending">
                  <Icon styles={{root: {paddingLeft: '5px', color: 'red'}}} iconName="Sync" />
                </TooltipHost>
              )}
            </>
          );
        }
      },
      {
        key: ColumnKey.RelevantTo,
        exportToExcel: true,
        name: 'Related To',
        minWidth: 135
      },
      {
        key: ColumnKey.Revision,
        exportToExcel: true,
        name: 'Revision',
        data: SelectColumn,
        minWidth: 110
      },
      {
        key: ColumnKey.Processes,
        name: 'Process',
        data: ProcessesColumn,
        minWidth: 190
      },
      {
        key: ColumnKey.OperatingUnit,
        exportToExcel: true,
        name: 'Operating Unit',
        data: SelectColumn,
        minWidth: 150
      },
      {
        key: ColumnKey.FunctionString,
        exportToExcel: true,
        name: 'Function',
        data: SelectColumn,
        minWidth: 105
      },
      {
        key: 'businessLines',
        exportToExcel: true,
        name: 'Business Line',
        data: MultiselectColumn,
        minWidth: 190
      },
      {
        key: 'industries',
        exportToExcel: true,
        name: 'Industry',
        data: MultiselectColumn,
        minWidth: 130
      },
      {
        key: 'specialities',
        exportToExcel: true,
        name: 'Specialty',
        data: MultiselectColumn,
        minWidth: 110
      },
      {
        key: 'projectSizeCategories',
        exportToExcel: true,
        name: 'Project Size Category',
        data: MultiselectColumn,
        minWidth: 190
      }
    ];
    return cols;
  }, []);

  return (
    <>
      {data && data.length > 0 && (
        <div className={styles.processBox}>
          <div className={styles.title}>
            <span>Recently Added Documents</span>
          </div>
          <EnhancedDetailsList<DocumentRecent, DocumentRecent, DocumentRecent>
            columns={columns}
            items={data || []}
            frozen
          />
        </div>
      )}
    </>
  );
};
