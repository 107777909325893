import {ITreeColumn, ITreeColumnBulk} from '../TreeColumn';
import {ITreeItem} from '../ITreeItem';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {exportToExcel} from '../../../../helpers/excel';
import {ProcessType} from '../../../../services/generated';

export const useTreeBuilderFull = <ITEM extends ITreeItem>(
  items: ITEM[],
  editColumnPosition: number | undefined,
  columns: ITreeColumn<ITEM>[],
  getBulkModel: (() => ITEM) | undefined,
  initialOpen: number[] | undefined,
  excelFileName?: string,
  filterDeleted?: boolean,
  hideButtons?: boolean,
  onlyVisibleItemsToExcel?: boolean,
  addGroupingBy?: string,
  turnOffEditNonProcess?: boolean
) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const model = useRef(getBulkModel ? getBulkModel() : null);

  const openedItems = useRef(initialOpen);
  const setOpenedItems = useCallback((item: number, isOpen: boolean) => {
    const newItems = (openedItems.current || []).filter(openedItem => openedItem !== item);
    if (isOpen) newItems.push(item);
    openedItems.current = newItems;
  }, []);

  const maxHeight = useRef<string | undefined>(undefined);
  const onMaxHeightCalc = useCallback(
    (newMaxHeight: string) => (maxHeight.current = newMaxHeight),
    []
  );

  const [isBulkOpen, setBulkOpen] = useState(false);
  const onBulkOpen = useCallback(() => {
    model.current = getBulkModel ? getBulkModel() : null;
    setBulkOpen(true);
  }, [getBulkModel]);
  const onBulkClose = useCallback(() => setBulkOpen(false), []);

  const [bulkCounter, setBulkCounter] = useState(0);
  const incrementBulk = useCallback(() => {
    setBulkCounter(bulkCounter + 1);
    setIdsForEdit([]);
    items.forEach(item => {
      if (item.isEdit) item.isEdit = false;
    });
  }, [bulkCounter, items]);

  useEffect(() => {
    incrementBulk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const [idsForEdit, setIdsForEdit] = useState<number[]>([]);

  const columnsWithEdit = useMemo(() => {
    const newColumns = [...columns];
    if (!hideButtons && editColumnPosition)
      newColumns.splice(editColumnPosition, 0, {
        key: 999888777,
        title: 'Edit/Export',
        minCSSWidth: '95px',
        maxCSSWidth: '95px',
        type: 'edit',
        onChange: setIdsForEdit
      });
    return newColumns;
  }, [editColumnPosition, columns, hideButtons]);

  const [bulkActiveProperties, setBulkActiveProperties] = useState<(string | number | symbol)[]>(
    []
  );

  const setActiveProperty = useCallback(
    (column: ITreeColumnBulk<ITEM>, isActive: boolean) => {
      const newBulkActiveProperties = bulkActiveProperties.filter(
        property => property !== column.property
      );
      if (isActive) {
        newBulkActiveProperties.push(column.property);
      }
      setBulkActiveProperties(newBulkActiveProperties);
    },
    [bulkActiveProperties]
  );

  const [showErrors, setShowErrors] = useState(false);
  const onConfirmForm = useCallback(() => {
    setShowErrors(true);
    if (
      bulkActiveProperties.some(prop => {
        const column = columns.find(col => col.type === 'bulk' && col.property === prop) as
          | ITreeColumnBulk<ITEM>
          | undefined;
        return column?.required && !(model.current as any)[column.property];
      })
    ) {
      return;
    }
    idsForEdit.forEach(id => {
      const item = items.find(item => item.id === id);
      if (
        item &&
        item.isChecked !== false &&
        (turnOffEditNonProcess === false || item.type === ProcessType.Process)
      ) {
        bulkActiveProperties.forEach(prop => {
          const column = columns.find(
            column => column.type === 'bulk' && column.property === prop
          ) as ITreeColumnBulk<ITEM>;
          if (column?.onCustomBulkChange) {
            column.onCustomBulkChange(item, (model.current as any)[prop]);
          } else {
            (item as any)[prop] = (model.current as any)[prop];
          }
        });
      }
    });
    model.current = getBulkModel ? getBulkModel() : null;
    setShowErrors(false);
    onBulkClose();
    setBulkActiveProperties([]);
    incrementBulk();
  }, [
    idsForEdit,
    bulkActiveProperties,
    items,
    columns,
    turnOffEditNonProcess,
    getBulkModel,
    onBulkClose,
    incrementBulk
  ]);

  const sortItemsLikeTree = useCallback((allItems: ITEM[], parentId?: number): ITEM[] => {
    const sortedItems: ITEM[] = [];
    const currentItems = allItems.filter(item =>
      parentId ? item.parentId === parentId : !item.parentId
    );
    for (const currentItem of currentItems) {
      sortedItems.push(currentItem);
      const children = sortItemsLikeTree(allItems, currentItem.id);
      sortedItems.push(...children);
    }
    return sortedItems;
  }, []);

  const onExportExcel = useCallback(
    async (includeDeleted?: boolean, onlySelected?: boolean) => {
      const exportColumns = columns.filter(
        column => column.exportToExcel && column.property
      ) as (ITreeColumn<ITEM> & {property: string | number | symbol})[];
      const exportItems = [];
      const sortedItems = sortItemsLikeTree(items);
      let getVisibleElementsIds;
      if (onlyVisibleItemsToExcel) {
        const ele = document.getElementsByClassName('linkTitle');
        getVisibleElementsIds = [...ele].map(e => e.getAttribute('data-attr-id'));
      }
      const filteredItems = onlySelected
        ? sortedItems.filter(item => idsForEdit.some(id => id === item.id))
        : sortedItems;
      for (const item of filteredItems) {
        if (item.isChecked || includeDeleted === true) {
          const exportItem: {[key: string | number | symbol]: any} = {};
          for (const column of exportColumns) {
            const prop = column.property as string | number | symbol;
            if (column.type === 'edit' || column.type === 'inheritStatusCheckbox') {
            } else if (column.type === 'title') {
              exportItem.title = column.getExportToExcelValue
                ? await column.getExportToExcelValue(item)
                : column.getTitle(item);
            } else if (prop) {
              const cellValue = column.getExportToExcelValue
                ? await column.getExportToExcelValue(item)
                : (item as any)[prop];
              const shouldBeCollapsed = getVisibleElementsIds
                ? getVisibleElementsIds.some(element => element && Number(element) === item.id)
                : false;
              exportItem[prop] =
                typeof cellValue === 'string' ? cellValue.slice(0, 32766) : cellValue;
              exportItem['isElementVisible'] = shouldBeCollapsed;
            }
          }
          exportItems.push(exportItem);
        }
      }
      exportToExcel(exportItems, exportColumns, excelFileName, addGroupingBy);
    },
    [
      columns,
      sortItemsLikeTree,
      items,
      onlyVisibleItemsToExcel,
      excelFileName,
      addGroupingBy,
      idsForEdit
    ]
  );

  const exportMenuProps = useMemo(
    () => ({
      items: [
        ...(filterDeleted
          ? []
          : [
              {
                key: 'all',
                text: 'Export all (include deleted)',
                iconProps: {
                  iconName: 'BulletedList2'
                },
                onClick: () => {
                  onExportExcel(true);
                }
              }
            ]),
        {
          key: 'selected',
          text: 'Export selected (in Edit/Export column)',
          disabled: idsForEdit.length === 0,
          iconProps: {
            iconName: 'MultiSelectMirrored'
          },
          onClick: () => {
            onExportExcel(true, true);
          }
        }
      ]
    }),
    [onExportExcel, idsForEdit, filterDeleted]
  );

  return useMemo(
    () => ({
      wrapperRef,
      isBulkOpen,
      onBulkOpen,
      onBulkClose,
      bulkCounter,
      idsForEdit,
      columnsWithEdit,
      onConfirmForm,
      model: model.current,
      setActiveProperty,
      showErrors,
      openedItems,
      setOpenedItems,
      maxHeight,
      onMaxHeightCalc,
      onExportExcel,
      exportMenuProps
    }),
    [
      wrapperRef,
      isBulkOpen,
      onBulkOpen,
      onBulkClose,
      bulkCounter,
      idsForEdit,
      columnsWithEdit,
      onConfirmForm,
      model,
      setActiveProperty,
      showErrors,
      openedItems,
      setOpenedItems,
      maxHeight,
      onMaxHeightCalc,
      onExportExcel,
      exportMenuProps
    ]
  );
};
