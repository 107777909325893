import {DocumentContentType, FunctionDto, Links, SpecialityDto} from '../services/generated';

export interface IDocumentBase {
  id: number;
  readonly link: Array<Links> | null;
  deleted: boolean;
  documentOwner: string | null;
  version: number;
  approved: string | null;
  approvedBy: string | null;
  documentId: string | null;
  documentKind: string | null;
  editStatus: string | null;
  function: FunctionDto | null;
  isLatestVersion: boolean;
  inReview: boolean;
  languageCode: string | null;
  lifecycleStatus: string | null;
  name: string | null;
  operatingUnit: string | null;
  prepared: string | null;
  relevantToId: number | null;
  relevantTo: string | null;
  reviewedBy: string | null;
  revisionId: string | null;
  securityLevel: string | null;
  specialities: SpecialityDto[] | null;
  title: string | null;
  titleEn: string | null;
  url: string | null;
  contentType: DocumentContentType;
}

/**
 * DocumentVersionDto, EditVersionDocumentDto, SlimDocumentDto, Document
 */
export class DocumentBase {
  public id: number;
  public readonly link: Array<Links> | null;
  public deleted: boolean;
  public documentOwner: string | null;
  public version: number;
  public approved: string | null;
  public approvedBy: string | null;
  public documentId: string | null;
  public documentKind: string | null;
  public editStatus: string | null;
  public function: FunctionDto | null;
  public isLatestVersion: boolean;
  public inReview: boolean;
  public languageCode: string | null;
  public lifecycleStatus: string | null;
  public name: string | null;
  public operatingUnit: string | null;
  public prepared: string | null;
  public relevantToId: number | null;
  public relevantTo: string | null;
  public reviewedBy: string | null;
  public revisionId: string | null;
  public securityLevel: string | null;
  public specialities: SpecialityDto[] | null;
  public title: string | null;
  public titleEn: string | null;
  public url: string | null;
  public contentType: DocumentContentType;

  public contentTypeString: string = '';
  public functionString: string = '';

  constructor(document: IDocumentBase) {
    this.id = document.id;
    this.link = document.link;
    this.deleted = document.deleted;
    this.documentOwner = document.documentOwner;
    this.version = document.version;
    this.approved = document.approved;
    this.approvedBy = document.approvedBy;
    this.documentId = document.documentId;
    this.documentKind = document.documentKind;
    this.editStatus = document.editStatus;
    this.function = document.function;
    this.inReview = document.inReview;
    this.isLatestVersion = document.isLatestVersion;
    this.languageCode = document.languageCode;
    this.lifecycleStatus = document.lifecycleStatus;
    this.name = document.name;
    this.operatingUnit = document.operatingUnit;
    this.prepared = document.prepared;
    this.reviewedBy = document.reviewedBy;
    this.relevantToId = document.relevantToId;
    this.revisionId = document.revisionId;
    this.securityLevel = document.securityLevel;
    this.specialities = document.specialities;
    this.title = document.title;
    this.titleEn = document.titleEn;
    this.url = document.url;
    this.contentType = document.contentType;
    this.relevantTo = document.relevantTo;

    Object.defineProperty(this, 'contentTypeString', {
      get() {
        switch (this.contentType) {
          case DocumentContentType.Document:
            return 'Document';
          case DocumentContentType.DocumentLink:
          case DocumentContentType.DynamicLink:
            return 'External document link';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'functionString', {
      get() {
        return document.function?.value || '';
      }
    });
  }
}
