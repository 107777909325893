import {FC, useCallback, useState} from 'react';
import {ProcessComment} from '../../../../../models';
import {PeoplePicker} from '../../../../common/PeoplePicker';
import {TheButton} from '../../../../common/TheButton';
import {UserCommentThumbStatus} from '../../../../../services/generated';
import styles from './Feedback.module.scss';
import {useComments} from '../../../../../hooks/services/useComments';
import {FeedbackDelete} from './FeedbackDelete';
import {FeedbackReplyTo} from './FeedbackReplyTo';
import {Icon} from '@fluentui/react';
import {ElevateStatusEnum} from '../../../../../enums';

interface IFeedbackCommentProps {
  comment: ProcessComment;
  processId: number;
  allReplyTo: ProcessComment[] | undefined;
  level: number;
  refresh: (skipLoading?: boolean) => void;
  isResolved?: boolean;
}

export const FeedbackComment: FC<IFeedbackCommentProps> = ({
  comment,
  processId,
  level,
  refresh,
  isResolved
}) => {
  const {toggleLike, toggleDislike} = useComments();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);
  const [isReplyToPending, setIsReplyToPending] = useState(false);
  const stylesReplies: React.CSSProperties = {marginLeft: `calc(5% * ${level})`};
  const iconName: string = level < 2 ? 'Reply' : 'ReplyAll';
  const isElevatedToOpex = comment.elevatedStatus === ElevateStatusEnum.ToOpex;
  const onLike = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await toggleLike(comment.id);
      await refresh(true);
      setIsLoading(false);
    })();
  }, [comment.id, refresh, toggleLike]);
  const onDislike = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await toggleDislike(comment.id);
      await refresh(true);
      setIsLoading(false);
    })();
  }, [comment.id, refresh, toggleDislike]);
  const onDeleteOpen = useCallback(() => setIsDeletePending(true), []);
  const onDeleteClose = useCallback(() => setIsDeletePending(false), []);
  const onDeleted = useCallback(() => {
    (async () => {
      await refresh();
    })();
  }, [refresh]);

  const onReplyToOpen = useCallback(() => setIsReplyToPending(true), []);
  const onReplyToClose = useCallback(() => setIsReplyToPending(false), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftWrapper}>
        <div className={styles.date}>{comment.created.replace('T', ' ').split('.')[0]}</div>
        <div className={styles.thumbs}>
          <TheButton
            disabled={isLoading || isResolved}
            onClick={onLike}
            iconProps={{
              iconName:
                comment.userCommentThumbStatus === UserCommentThumbStatus.ThumbUp
                  ? 'LikeSolid'
                  : 'Like'
            }}>
            {comment.thumbUpCount}
          </TheButton>
          <TheButton
            disabled={isLoading || isResolved}
            onClick={onDislike}
            iconProps={{
              iconName:
                comment.userCommentThumbStatus === UserCommentThumbStatus.ThumbDown
                  ? 'DislikeSolid'
                  : 'Dislike'
            }}>
            {comment.thumbDownCount}
          </TheButton>
        </div>
        {comment.canDeletedByMe ? (
          <TheButton
            onClick={onDeleteOpen}
            disabled={isLoading || isResolved}
            classNameButton={styles.delete}
            iconProps={{iconName: 'Trash'}}>
            Delete
          </TheButton>
        ) : null}
      </div>
      <div className={`${styles.commentContainer} ${isResolved ? styles.grayOut : undefined}`}>
        <div className={styles.repliesMarginLeft} style={{...stylesReplies}}>
          <PeoplePicker isReadonly defaultSelectedPersonId={comment.createdBy?.azureId} />
        </div>
        <div className={styles.commentWithArrow} style={{...stylesReplies}}>
          <Icon iconName={iconName} className={styles.arrow} />
          <div
            className={`sun-editor sun-editor-editable can-copy ${styles.comment} ${styles.sunEditor}`}
            dangerouslySetInnerHTML={{
              __html: comment.comment || ''
            }}
          />
        </div>
        {level < 2 && (!isResolved || comment.elevatedStatus !== ElevateStatusEnum.None) ? (
          <div className={styles.buttonContainer}>
            <TheButton
              onClick={onReplyToOpen}
              primary
              disabled={isLoading || isResolved || isElevatedToOpex}
              iconProps={{iconName: 'Reply'}}>
              Reply To
            </TheButton>
          </div>
        ) : null}
      </div>
      <FeedbackDelete
        comment={comment}
        isOpen={isDeletePending}
        onClose={onDeleteClose}
        refresh={onDeleted}
      />
      <FeedbackReplyTo
        processId={processId}
        comment={comment}
        isOpen={isReplyToPending}
        onClose={onReplyToClose}
        refresh={onDeleted}
      />
    </div>
  );
};
