import {DocumentBase} from './DocumentBase';
import {DocumentUsedDto} from '../services/generated/models/DocumentUsedDto';
import {ProjectSizeCategoryDto, IndustryDto, UserDto, BusinessLineDto} from '../services/generated';
import {ProcessMinimum} from './ProcessMinimum';

export class DocumentInUnit extends DocumentBase implements DocumentUsedDto {
  public ownerProcessId: number;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public industries: IndustryDto[] | null;
  public processes: Array<ProcessMinimum> | null;
  public auditable: boolean;

  public created: string | null;
  public createdBy: UserDto | null;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public businessLines: BusinessLineDto[] | null;

  constructor(document: DocumentUsedDto) {
    super(document);
    this.ownerProcessId = document.ownerProcessId;
    this.projectSizeCategories = document.projectSizeCategories;
    this.industries = document.industries;
    this.processes = document.processes?.map(process => new ProcessMinimum(process)) || null;
    this.auditable = document.auditable;

    this.created = document.created;
    this.createdBy = document.createdBy;
    this.modified = document.modified;
    this.modifiedBy = document.modifiedBy;
    this.businessLines = document.businessLines;
  }
}
