import {FC} from 'react';
import {Activity} from '../../../../models';
import {Accordion} from '../../../common/Accordion';
import styles from '../Process.module.scss';
import {ActivitiesList} from '../../../common/ProcessSections/ActivitiesList';

interface IProcessActivitiesProps {
  activities: Activity[] | null;
  isInput: boolean;
  unitId: number;
}

export const ProcessActivities: FC<IProcessActivitiesProps> = ({activities, isInput, unitId}) => {
  return activities?.length ? (
    <div className={styles.processLinksWrapper}>
      <Accordion
        isOpen={!!activities?.length}
        header={`Activity ${isInput ? 'inputs' : 'outputs'}`}>
        <ActivitiesList data={activities} isInput={isInput} unitId={unitId} />
      </Accordion>
    </div>
  ) : null;
};
