import {Dropdown, IDropdownOption, Spinner, TooltipHost} from '@fluentui/react';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {InputValidationError} from '../Input/ValidationError';
import {IDictionaryMultiselectDropdownProps, IDictionaryItem} from './IDictionaryDropdownProps';
import {dropdownStyles} from './DictionaryDropdown';

export const DictionaryMultiselectDropdown: FC<IDictionaryMultiselectDropdownProps> = ({
  defaultValues,
  onChange,
  className,
  disabled,
  options,
  required,
  disableErrorMessage,
  placeholder
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    defaultValues && defaultValues.length
      ? defaultValues.map(val => (typeof val === 'number' ? val.toString() : val?.id.toString()))
      : []
  );
  const [tooltip, setTolltip] = useState<string>(
    defaultValues && defaultValues.length
      ? defaultValues
          .map(val => (typeof val === 'number' ? val.toString() : val?.value?.toString()))
          .join(', ')
      : ''
  );
  const dictionaryOptions = useMemo<IDropdownOption<IDictionaryItem>[] | undefined>(
    () =>
      options?.map(option => ({
        key: option.id + '',
        text: option.value || '',
        data: option,
        title: option.value || ''
      })),
    [options]
  );
  const prepareTooltip = useCallback(
    (selectedItems: string[]) => {
      let tooltip: string[] = [];
      options?.forEach(x => {
        if (selectedItems.find(y => y === x.id.toString())) {
          if (x.value) tooltip.push(x.value);
        }
      });
      setTolltip(tooltip.join(', '));
    },
    [options]
  );
  const onInputChange = useCallback(
    (
      event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
      index?: number | undefined
    ) => {
      if (option) {
        const selectedItems = option.selected
          ? [...selectedKeys, option.key.toString()]
          : selectedKeys.filter(key => key !== option.key.toString());
        prepareTooltip(selectedItems);
        setSelectedKeys(selectedItems);
      }
    },
    [selectedKeys, prepareTooltip]
  );
  useEffect(() => {
    if (dictionaryOptions) {
      const data: IDictionaryItem[] = [];
      dictionaryOptions
        .filter(opt => selectedKeys.includes(opt.key.toString()) && !!opt.data)
        .forEach(item => {
          if (item.data) data.push(item.data);
        });
      onChange(data);
    }
  }, [selectedKeys, dictionaryOptions, onChange]);

  return !options ? (
    <Spinner />
  ) : (
    <>
      <div className={className}>
        <TooltipHost content={tooltip}>
          <Dropdown
            defaultSelectedKeys={selectedKeys}
            onChange={onInputChange}
            placeholder={placeholder ?? 'Select an option'}
            options={dictionaryOptions || []}
            disabled={disabled || options?.length === 0}
            required={required}
            multiSelect
            styles={dropdownStyles}
          />
        </TooltipHost>
      </div>
      {!disableErrorMessage && !disabled && !selectedKeys.length && required ? (
        <InputValidationError message={'This value could not be empty. Select value.'} />
      ) : null}
    </>
  );
};
