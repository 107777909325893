import {EnhancedDetailsList} from '../EnhancedDetailsList';
import {Activity} from '../../../models';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {sortByTitle} from '../../../helpers/arrayMehods';
import {getColumnsAdd, getColumnsEdit, getColumnsView} from './activitiesListColumns';
import {useActivities} from '../../../hooks/services/useActivities';
import {ActivitiesListAddModal} from './ActivitiesListAddModal';
import {UnitDto} from '../../../services/generated';
import {useUser} from '../../../hooks/services/useUser';

export const ActivitiesList: FC<{
  data: Activity[];
  changedData?: Activity[];
  unitId: number;
  allowAdd?: boolean;
  ownerProcessId?: number;
  pageSize?: number;
  isInput: boolean;
}> = ({data, changedData, allowAdd, ownerProcessId, pageSize, isInput, unitId}) => {
  const {getActivityInputs, getActivityOutputs} = useActivities();
  const {getUserIsPortalOwner} = useUser();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activitiesCreatedCount, setActivitiesCreatedCount] = useState(0);
  const [editItem, setEditItem] = useState<Activity>();
  const onOpen = useCallback(() => setIsAddModal(true), []);
  const [unitPortalOwner, setUnitPortalOwner] = useState<UnitDto[]>([]);

  useEffect(() => {
    (async () => {
      const units = await getUserIsPortalOwner();
      if (units.result) setUnitPortalOwner(units.result);
    })();
  }, [getUserIsPortalOwner]);

  const onEditClick = useCallback(
    (item: Activity) => {
      setEditItem(item);
      onOpen();
    },
    [onOpen]
  );
  const refreshLinks = useMemo(
    () => async () => {
      const response = await (isInput ? getActivityInputs(unitId) : getActivityOutputs(unitId));
      if (!response.result) return;
      setActivities(response.result.sort(sortByTitle));
    },
    [isInput, getActivityInputs, unitId, getActivityOutputs]
  );
  const [isAddModal, setIsAddModal] = useState(false);

  useEffect(() => {
    if (!allowAdd) return;
    refreshLinks();
  }, [refreshLinks, allowAdd]);

  const columnsView = useMemo(
    () => getColumnsView(changedData, ownerProcessId),
    [changedData, ownerProcessId]
  );
  const columnsEdit = useMemo(
    () => getColumnsEdit(changedData, ownerProcessId, onEditClick, unitPortalOwner),
    [changedData, ownerProcessId, onEditClick, unitPortalOwner]
  );
  const columnsAdd = useMemo(
    () => getColumnsAdd(changedData, ownerProcessId),
    [changedData, ownerProcessId]
  );

  const updateActivity = useCallback(
    (modifiedLink: Activity, existingLinks: Activity[] | undefined) => {
      const modifiedLinks = existingLinks?.filter(item => item.id === modifiedLink?.id);
      modifiedLinks?.forEach(link => {
        link.title = modifiedLink.title;
        link.url = modifiedLink.url;
        link.projectSizeCategories = modifiedLink.projectSizeCategories;
        link.specialities = modifiedLink.specialities;
        link.function = modifiedLink.function;
      });
      return !!modifiedLinks?.length;
    },
    []
  );

  const onModalClose = useCallback(() => setIsAddModal(false), []);
  const onCreated = useCallback(
    (added: Activity | undefined, modifiedActivity?: Activity) => {
      if (added) {
        if (ownerProcessId) {
          added.ownerProcessId = ownerProcessId;
        }
        if (changedData) {
          changedData.push(added);
          setActivitiesCreatedCount(activitiesCreatedCount + 1);
        }
      }
      if (modifiedActivity) {
        const modified = updateActivity(modifiedActivity, data);
        const changed = updateActivity(modifiedActivity, changedData);
        if (modified || changed) setActivitiesCreatedCount(activitiesCreatedCount + 1);
      }
      refreshLinks();
      setIsAddModal(false);
    },
    [refreshLinks, ownerProcessId, changedData, activitiesCreatedCount, updateActivity, data]
  );
  return (
    <>
      <EnhancedDetailsList<Activity, Activity, Activity>
        key={activitiesCreatedCount}
        onCreateItemClick={() => setIsAddModal(true)}
        columns={allowAdd ? columnsEdit : columnsView}
        items={data}
        allowAdd={allowAdd}
        addLabel="Add activity"
        columnsForAdd={columnsAdd}
        itemsToAdd={activities}
        changedItems={changedData}
        mapChangedItemToItem={link => link}
        mapNewItemToChangedItem={link => {
          return new Activity(link);
        }}
        ownerProcessId={ownerProcessId}
        pageSize={pageSize}
      />
      <ActivitiesListAddModal
        isVisible={isAddModal}
        onClose={onModalClose}
        onCreated={onCreated}
        isInput={isInput}
        allowAdd={!editItem}
        editItem={editItem}
        ownerProcessId={ownerProcessId}
      />
    </>
  );
};
