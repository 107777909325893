import {BusinessLineDto, DocumentVersionDto, UserDto} from '../services/generated';
import {DocumentSystem} from './DocumentSystem';

export class DocumentVersion extends DocumentSystem implements DocumentVersionDto {
  public relativeUrl: string | null;
  public created: string | null;
  public createdBy: UserDto | null;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public businessLines: BusinessLineDto[] | null;

  constructor(document: DocumentVersionDto) {
    super(document);
    this.relativeUrl = document.relativeUrl;
    this.created = document.created;
    this.createdBy = document.createdBy;
    this.modified = document.modified;
    this.modifiedBy = document.modifiedBy;
    this.businessLines = document.businessLines;
  }
}
