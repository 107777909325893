import {useMemo} from 'react';
import {SearchResult} from '../../models/SearchResult';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {SearchService} from '../../services/generated';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';
import {useUnit} from './useUnit';

export const useSearch = () => {
  const {getUnits} = useUnit();
  const requestWrapper = useApiService();

  /** Returns a list of all units */
  const getSearchResults = async (
    keyword: string,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<SearchResult[]> => {
    const units = await getUnits();
    return await requestWrapper(
      SearchService.getApiSearch(keyword),
      result => {
        return result.map(item => {
          const unit = units.result?.find(unit => unit.id === item.unitId)?.title || undefined;
          return new SearchResult(item, unit);
        });
      },
      log
    );
  };
  

  return useMemo(
    () => ({
      getSearchResults,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
